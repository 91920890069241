//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gsap from 'gsap';
import {mapState} from 'vuex';

export default {
  computed: mapState(['page']),
  methods: {
    transitionAnimation () {
      const sourceImg = this.$refs.sourceImg;
      const overlayImg = this.$refs.overlayImg;
      const sourceBounding = sourceImg.getBoundingClientRect();
      const sameLeft = sourceBounding.left;
      const sameRight = window.innerWidth - sourceBounding.right;
      const sameTop = sourceBounding.top;
      const sameBottom = window.innerHeight - sourceBounding.bottom;

      gsap.fromTo(overlayImg, {
        left: sameLeft,
        right: sameRight,
        top: sameTop,
        bottom: sameBottom,
        autoAlpha: 1,
      }, {
        left: 0,
        right: 0,
        top: 64,
        bottom: 0,
        duration: 0.5,
        ease: 'power1.inOut',
        onComplete: () => {
          this.$router.push({
            name: 'kaart',
          });
        },
      });
    },
  },
};
