import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0e729414&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/data/www/portal.vervoerregio.nl/vva-autodeploy/releases/20211223-130349_799dc3b3/verkeerskaart/components/AppHeader.vue').default,Usps: require('/data/www/portal.vervoerregio.nl/vva-autodeploy/releases/20211223-130349_799dc3b3/verkeerskaart/components/Usps.vue').default,About: require('/data/www/portal.vervoerregio.nl/vva-autodeploy/releases/20211223-130349_799dc3b3/verkeerskaart/components/About.vue').default,AppFooter: require('/data/www/portal.vervoerregio.nl/vva-autodeploy/releases/20211223-130349_799dc3b3/verkeerskaart/components/AppFooter.vue').default})
