//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import page from '~/plugins/mixins/page';

export default {
  mixins: [page],
};
